<script setup lang="ts">
import { BaseTooltip } from '@legacy-studio/library';

const props = defineProps<{
	title: string;
	description?: string;
	time: number;
}>();

const isOpen = ref(false);
const timer = ref<NodeJS.Timeout | null>(null);

function open() {
	isOpen.value = true;
	timer.value && clearTimeout(timer.value);
	timer.value = setTimeout(() => {
		isOpen.value = false;
	}, props.time);
}

defineExpose({
	open,
});
</script>

<template>
	<BaseTooltip
		:force-open="isOpen"
		:force-close="!isOpen"
		:title="title"
		:description="description"
	>
		<slot />
	</BaseTooltip>
</template>
