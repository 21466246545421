<script setup lang="ts">
import { BaseBadge, copyToClipboard } from '@legacy-studio/library';
import TooltipAlert from './TooltipAlert.vue';

withDefaults(
	defineProps<{
		message?: string;
		badgeBackgroundColor?: string;
	}>(),
	{
		message: 'Скопировано',
		badgeBackgroundColor: '',
	},
);

defineSlots<{
	default: {
		copy: (value: string) => void;
	};
}>();

const tooltip = ref<InstanceType<typeof TooltipAlert> | null>(null);
function copy(value: string) {
	copyToClipboard(value);
	tooltip.value?.open();
}
</script>

<template>
	<TooltipAlert
		ref="tooltip"
		:title="message"
		:time="1500"
		class="h-fit w-fit"
	>
		<BaseBadge
			color="gray"
			:class="badgeBackgroundColor"
		>
			<slot :copy="copy" />
		</BaseBadge>
	</TooltipAlert>
</template>
